@import 'variables'

@import '~bootstrap/scss/bootstrap'

@font-face
  font-family: 'NeueMachina'
  src: url('../fonts/NeueMachina-Regular.ttf') format('truetype')
  font-weight: 800
  font-style: normal

*
  font-family: 'NeueMachina', sans-serif

a
  text-decoration: none

  &.active
    color: #3BFEC1


.form-input, .form-input:focus
  border: none
  color: #fff
  border-radius: 20px
  background-color: #393939
  padding: 20px
  padding-right: 30px
  padding-left: 30px
  box-shadow: none

  &::placeholder
    color: #8A8A8A

.btn-outline-success
  padding: 10px 30px
  border-radius: 20px
  color: #fff
  font-weight: 200

  &:hover
    color: #000

.btn-outline-secondary
  padding: 10px 30px
  border-radius: 20px
  color: #fff
  font-weight: 200

  &:hover
    color: #fff

.modal-content
  background: none
  border: none

.btn-close
  height: unset
  width: unset
  border-radius: unset
  opacity: unset

.modal-header
  border-bottom: unset

.modal-body
  padding: 10px 40px

  .form
    background: #292929
    border-radius: 20px
    min-height: 200px
    border-color: #797979
    border-width: 1px
    border-style: solid
    color: #fff
    padding: 20px 60px

    .title
      text-align: center
      font-size: 40px

    .register
      color: #949494

      & a
        color: #3BFF9D

        &:hover
          text-decoration: underline

    .forget
      border-top: 1px solid #454545

      & a
        color: #949494
        font-size: 13px

        &:hover
          text-decoration: underline

    .error
      font-size: 13px
      color: red

.generate-image
  max-height: 500px
  max-width: 500px
  border-radius: 25px

.like
  background-image: url(/images/like.svg)
  background-repeat: no-repeat
  width: 55px
  height: 55px
  border-radius: 16px

  &:hover
    cursor: pointer
    background-color: #000

.generate-like
  position: absolute

.gallery

  img
    width: 100%
    border-radius: 16px

    &:hover
      cursor: pointer

  .col-2
    padding: 5px

  .like_block
    color: #fff
    position: absolute
    height: 40px
    border: 1px solid #797979
    border-radius: 14px
    background: #2d2c2c
    display: flex
    margin-top: 5px
    margin-left: 5px

    .like
      background-image: url(/images/like_off.svg)
      width: 30px
      height: 30px
      background-repeat: no-repeat
      background-size: 30px
      margin-top: 4px
      margin-left: 4px
      border-radius: unset

      &:hover
        cursor: pointer
        background-color: unset
        background-image: url(/images/like_on.svg)

      &.like_on
        background-image: url(/images/like_on.svg)

    .count
      line-height: 35px
      padding: 0px 10px

#modalGallery

  .model
    max-width: 300px
    max-height: 300px
    border-radius: 20px

  .col-6
    padding: 0

  .modal-body .form
    padding: 20px 30px

  .prompt
    background-color: #393939
    border-radius: 15px
    padding: 20px
    max-height: 200px

  .author
    font-size: 13px
    color: #ffffff70

  .btn
    padding: 10px 20px
    font-size: 13px
    width: 90%


.generate-h1
  font-size: 60px
  line-height: 60px

@media only screen and (max-width: 540px)

  .navbar-collapse-ico
    background-image: url(/images/collapse.svg)
    width: 50px
    height: 50px
    margin-left: 5px

  .navbar-toggler:focus
    box-shadow: none

  .navbar
    padding: 0

  .nav
    img
      width: 75%

  .login-button
    background-image: url(/images/login.svg)
    width: 50px
    height: 50px
    margin-right: 20px
    margin-top: 5px

  .modal-body
    padding: unset

    .form
      padding: 20px 20px

  .generate-h1
    font-size: 40px
    line-height: 50px

  .generate-image
    max-height: unset
    max-width: 100%

  .gallery-modal-flow
    flex-direction: column-reverse !important
